import * as React from 'react'

import { AnchorButton } from '@/components/Button'
import { Modal } from '@/components/Modal'
import proj4 from 'proj4'
import {
  buriedCulturalPropertyLinks,
  fixedAssetTaxStreetPriceLink,
  inheritanceTaxRoadValueLink,
  chikaKojiChosaLink,
  parcelSupportMapLink,
  hazardMapLinks,
} from '@/models/constUrl'
import { LoadingOverlay } from '@/components/Loading/LoadingOverlay'


export const MarketDatumLinkModal: React.FC<{
  open: boolean
  onClose: () => void
  path: string
  query?: string
  title?: string
  onMarketDatumLink?: boolean
  btnListStyle?: string
  property?: any
  setProperty?: any
  setJreit?: any
  setBuriedCulturalAlertOpen?: any
  setJreitPropertyPartsModalAlertOpen?: any
  setJreitPropertyCategory?: any
  middleModalLinkers?: any
  closeBuriedCulturalAlertModal?: any
  closeJreitPropertyPartsModalAlertOpen?: any
}> = ({open, onClose, path, query, title, onMarketDatumLink = true, btnListStyle = `w-full h-[36px] text-white`, setProperty, property, setJreit, setBuriedCulturalAlertOpen, setJreitPropertyPartsModalAlertOpen, setJreitPropertyCategory,middleModalLinkers}) => {
  const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content')

  const [isLoading, setIsLoading] = React.useState(false)
  React.useEffect(() => {
    if (onMarketDatumLink && open) {
      if (path.includes('/properties/')) {
        const match = path.match(/\/properties\/([a-zA-Z0-9]+)/);
  
        if (match) {
          setIsLoading(true)
          fetch('/market_data/market_datum_link', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              'X-CSRF-Token': csrfToken,
            },
            body: JSON.stringify({
              purpose: "properties",
              match: match,
            }),
          })
            .then((response) => {
              if (!response.ok) throw new Error('接続に失敗しました');
              return response.json();
            })
            .then((data) => {
              console.log('Success:', data);
              console.log('property_result:', data.property_result);
              console.log('jreit_result:', data.jreit_result);
              setProperty(data.property_result);
              setJreit(data.jreit_result);
            })
            .catch((error) => {
              console.error('Error:', error);
            })
            .finally(() => {
              console.log("finish");
              setIsLoading(false)
            });
        }
      }
    }
  }, [open]);

  proj4.defs(
    'EPSG:4301',
    '+proj=longlat +ellps=bessel +towgs84=-146.414,507.337,680.507,0,0,0,0 +no_defs +type=crs'
  )
  const [propertyLat, propertyLng] = property?.address_geocoding || [null, null];

  const getPropertyHashid = () => {
    if (path.includes('/properties/')) {
      const match = path.match(/\/properties\/([a-zA-Z0-9]+)/)
      return match ? match[1] : null
    }
    if (path.includes('/property_public_files/')) {
      return new URLSearchParams(window.location.search).get('property_id')
    }
  }

  const replacePath = () => {
    if (path.includes('/property_public_files/')) {
      return `/properties/${getPropertyHashid()}`
    } else {
      return path
    }
  }

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <LoadingOverlay isOpen={isLoading} text="通信中" />
        <div className="p-4">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-lg font-bold">{title || '周辺情報'}</h2>
          </div>

          <div className="grid grid-cols-10 gap-4">
            <div className="col-span-4">
              <h3 className="text-md font-semibold mb-2">周辺事例</h3>
              <div className="flex flex-col gap-2">
                <AnchorButton
                  className={`${btnListStyle}`}
                  size="small"
                  href={`${replacePath()}?data_type=for_sale&${query}`}
                >
                  周辺売買事例
                </AnchorButton>
                <AnchorButton
                  className={`${btnListStyle}`}
                  size="small"
                  href={`${replacePath()}?${query}&tab_index=1&data_type=for_rent`}
                >
                  周辺賃貸事例
                </AnchorButton>
              </div>
            </div>

            <div className="col-span-6">
              <h3 className="text-md font-semibold mb-2">外部リンク</h3>
              <div className="grid grid-cols-2 gap-2">
                <AnchorButton
                  className={`${btnListStyle}`}
                  target="_blank"
                  size="small"
                  href={fixedAssetTaxStreetPriceLink(propertyLng, propertyLat)}
                >
                  固定資産税路線価
                </AnchorButton>
                <AnchorButton
                  className={`${btnListStyle}`}
                  target="_blank"
                  size="small"
                  href={inheritanceTaxRoadValueLink(propertyLng, propertyLat)}
                >
                  相続税路線価
                </AnchorButton>
                <AnchorButton
                  className={`${btnListStyle}`}
                  target="_blank"
                  size="small"
                  href={`${chikaKojiChosaLink(propertyLng, propertyLat)}`}
                >
                  地価公示・地価調査
                </AnchorButton>
                <AnchorButton
                  className={`${btnListStyle}`}
                  size="small"
                  href={`${hazardMapLinks(getPropertyHashid())}`}
                >
                  ハザードマップ
                </AnchorButton>
                <AnchorButton
                  className={`${btnListStyle}`}
                  target="_blank"
                  size="small"
                  href={parcelSupportMapLink(propertyLat,propertyLng)}
                >
                  地盤サポートマップ
                </AnchorButton>
                {buriedCulturalPropertyLinks(property?.prefecture,property?.city) && (
                  <>
                    <div
                      className={`flex ${btnListStyle} items-center justify-center bg-[rgb(9_104_157)] cursor-pointer`}
                      onClick={() => {
                        setBuriedCulturalAlertOpen(true);
                        onClose();
                      }}
                    >
                      {middleModalLinkers.buriedCultural}
                    </div>
                  </>
                )}
                <div
                  className={`flex ${btnListStyle} items-center justify-center bg-[rgb(9_104_157)] cursor-pointer`}
                  onClick={() => {
                    setJreitPropertyPartsModalAlertOpen(true);
                    setJreitPropertyCategory(middleModalLinkers.jReitMapLink)
                    onClose();
                  }}
                >
                  {middleModalLinkers.jReitMapLink}
                </div>
                <div
                  className={`flex ${btnListStyle} items-center justify-center bg-[rgb(9_104_157)] cursor-pointer`}
                  onClick={() => {
                    setJreitPropertyPartsModalAlertOpen(true);
                    setJreitPropertyCategory(middleModalLinkers.reitdbMAPLink)
                    onClose();
                  }}
                >
                  {middleModalLinkers.reitdbMAPLink}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}